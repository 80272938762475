/*
 * Flex UX Framework
 *
 * Filename: home-featured-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// @TODO: rename markup
// @TODO: convert @media queries to use the mq mixin

.main-feature {

  height: 300px;
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  overflow: hidden;


}

#featured-1__content{
  .product-card, .product-cell {
      flex: 1 0 33.33%;
      width: 33.33%;
      max-width: 300px;
  }
  .product-list{
      margin: 0px -12px 12px -12px;


    @include mq("phone-wide", "max") {
      margin: 0px -6px 6px -6px;
    }
  }
}

#featured-2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  @include mq("phone-wide", "max") {
    margin-bottom: 19px;
  }

  .products-tab-bar, .mobile-only {
    display: none;
  }

  .product-list{
    @media only screen and (max-width: 995px) and (min-width: 753px){
      width: 75%;
      margin: auto;
    }

  }

  .title-text{
    margin-top: 24px;
    background: white;
    width: 100%;
    max-width: 948px;

    hr{
      margin: 20px auto;
    }

    h2{
      color: #008733;
      font-weight: 400;
      font-size: 30px;
      text-align: center;
      text-align: center;
      margin: 1.5% 0px;
    }
  }
}