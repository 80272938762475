/*
 * Flex UX Framework
 *
 * Filename: checkout-split-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container{
	.checkout-split-row {
		border-bottom: none;

		@include mq("tablet", "min"){
		    padding: 20px;
		}

		&--single-split {
			@include mq("tablet", "max"){
		    	flex-flow: column;
			}
		}

		.checkout-split-cell--controls-top{
			justify-content: flex-start;
		}

		.checkout-split-cell--controls-top, .checkout-split-cell--controls-bottom {
		    flex: 1 1 100%;

		    @include mq("tablet", "max"){
		    	justify-content: center;
			    flex-wrap: wrap;
			}

			button:not(:last-child){
			    @include mq("tablet", "min"){
				    margin-right: 20px;
			   }
			}
		}
	}


	.checkout-split-row-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;


		.checkout-split-row {
			flex: 1;
			&.checkout-split-row--controls {
				&:nth-of-type(1) {
					min-width: 205px;
					justify-content: flex-start;
				}

				&:nth-of-type(2) {
					min-width: 191px;
					justify-content: flex-end;
				}
			}

		    @include mq("phone-wide", "max"){
			    padding: 0px;
		   }

		}
	}
}