/*
 * Flex UX Framework
 *
 * Filename: basket-item-listing.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-item {
    display: flex;
    white-space: nowrap;
    border-bottom: 1px solid #efeff1;

    .checkout-item-main {
    	display: flex;
		align-items: center;
		flex: 8 8 80%;
		font-size: 14px;
		color: #000;

		a {
			text-align: center;
		}

        @include mq("phone-wide", "max"){
            padding-bottom: 20px;
            max-width: 205px;
        }

    }

    .checkout-item-controls {
		padding: 20px;
		flex-grow: 2;
		text-align: right;
		box-sizing: border-box;

		.checkout-item--control {
    		padding-top: 3px;
    		padding-bottom: 3px;
    		width: 100%;

    		.price {
    			font-weight: 400;
    		}

    		.mdl-textfield {
    			input {
    				line-height: 14px;
    				padding-left: 3px;
    			}
    		}
		}
	}	
}

