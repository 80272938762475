/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.hero-slide{
	display: flex;
	justify-content: center;

	.content{
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 100%;

		div{
			background: rgba(0,103,177, .50);
			h1{
				text-align: center;
				font-family: $primary-font-face;
				text-shadow: 0 0 9px rgba(0,0,0,0.75);
				color: #fff;
				font-size: 50px;
				font-weight: bold;
				height: 100%;
				display: flex;
				flex-flow: column;
				margin: 50px 65px;

		 		@include mq("tablet", "max") {
				    font-size: calc( 16px + 3.2vw);
				    margin: 5% 8%;
				}
			}
		}
	}
}