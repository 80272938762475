/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                       #0067B1;
$brand-color-secondary:             #000; //test color to see if this is used anywhere in this site
$brand-color-btn-primary:           $brand-color;
$brand-color-btn-primary-hover:     #00528E;
$brand-color-btn-secondary:         #CCE1EF;
$brand-color-btn-secondary-hover:   #E6F0F7;

$accent-color: 						pink; //test color to see if this is used anywhere in this site
$link-color:						$brand-color;
$link-hover: 						#003459;

$primary-font-color:                $brand-color;
$secondary-font-color:				#000;
$h1-font-color:                     #000;
$h2-font-color:                     #000;


//
// General: Font
// 

$h1-font-size:                      30px;
$h2-font-size:                      21px;
$primary-font-face:             	'Open Sans', 'sans-serif';
$secondary-font-face:        	   	'Open Sans', 'sans-serif';
$drawer-font:                    	$primary-font-face;

//
// Header
//

$header-background:                 #fff;
$header-top-background:				#3F3F3F;
$header-top-background-hover:       #4D4D4D;
$header-top-font-color:             #fff;
$header-main-font-color-hover:      $brand-color;
$header-main-nav-background-hover:   #f2f2f2;





$header-height:                     165px;
$header-top-height:                 40px;
$header-main-height:                124px; 

$header-logo-padding:               15px; 
$header-logo-height:                59px;
$header-logo-width:                 230px;

$header-mobile-height:              56px;

// $header-tablet-height:              56px;
// $header-mobile-height:               56px;
/*$header-logo-padding:               10px 10px 10px 27px;*/
$header-logo-padding:               10px;
$header-minicart-badge-color:       #000;
$header-minicart-badge-text-color:  #FFF;
$header-minicart-badge-border:      none; 
$header-top-link-hover-border:      2px solid rgba(255,255,255,0.5);
$header-icons-color:                $brand-color;
$header-search-font-color:          $primary-font-color;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #fff;


//
// Footer
//

$footer-background:                 #003873;
