/*
 * Flex UX Framework
 *
 * Filename: custom_orders.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.custom-orders {

	main {
		background: #fff !important;
	}

    .page-header {
        height: 0;
    }

	.page-body {

    	max-width: 610px;
    	margin: 0px auto;
    	padding: 12px 12px 50px 12px;
        padding-bottom: 50px;
    	font-size: 14px;
    	line-height: 1.5em;
    	font-family: $primary-font-face;

        @include mq('phone-wide', 'max'){
            padding: 15px;
            margin-top: 0;
        }
	}
}