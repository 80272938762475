/*
 * Flex UX Framework
 *
 * Filename: checkout_completed.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout_completed{
	main{
		background: white !important;
	}

	.page-body{
		max-width: 600px !important;
		padding: 20px;
	}

	.checkout-container{
		margin: 0px;
		margin-bottom: 25px;

		.checkout-main{

			p{
				font-size: 14px;
				line-height: 1.5;
			}

			.checkout-survey{
				margin: 25px auto;
				width: 100%;
				max-width: 600px;

				label{
					font-weight: 700;
					font-size: 16px;
					cursor: pointer;
					font-size: 14px;
					line-height: 1.15em;
					font-family: $primary-font-face;
					color: $primary-font-color;
					opacity: 1;
				}

				.select-wrapper{
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					justify-content: space-between;
					margin: 25px 0px;

					/* Reset Select */
					select {
						-webkit-appearance: none;
						-moz-appearance: none;
						-ms-appearance: none;
						appearance: none;
						outline: 0;
						box-shadow: none;
						border: 0 !important;
						background-image: none;
					}

					/* Remove IE arrow */
					select::-ms-expand {
						display: none;
					}

					.select-option-btn{
						margin-top: 15px;
						margin-bottom: 15px;
						select{
							background: none;
							font-size: 13px;
							font-family: $secondary-font-face;
							text-transform: uppercase;
							font-weight: 700;
							width: 100%;
							cursor: pointer;
						}
					}
				}

				.textarea{
					margin: 15px 0px 40px 0px;
					padding: 15px;
					display: block;
					overflow: hidden;
					resize: both;
					min-height: 32px;
					border-radius: 0px;
					//border: 2px solid $brand-color;
					background: #f3f4f6;

					&[placeholder]:empty:before{
						content: attr(placeholder);
						color: $primary-font-color;
						font-style: italic;
						opacity: .5;
					}
				}

				.button-row{
					width: 100%;
					display: flex;
					justify-content: center;
				}
			}

			.survey-thank-you{
				font-size: 16px;
				text-align: center;
				color: $brand-color;
				margin: 2.5em 0px;
				line-height: 1.15em;
			}
		}
	}
}