/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

/*.home.layout-v1{
 	main{
 		background-color: #ffffff;
	 	.spotlight{
			.page-header{
				display: flex;
				flex-flow: nowrap;

				@include mq("tablet", "max"){
					flex-direction:column-reverse;
					height: 100% !important;
				    min-height: unset !important;
				    max-height: unset !important;
				}

				&>div{
					&:first-of-type{
						height: inherit;
						@include mq("tablet", "max"){
							height: 287px;
						}
					}
				}

				.hero-carousel.slick-initialized.slick-slider{
					margin-left:425px;
					@include mq("tablet", "max"){
						height: 287px !important;
						margin-left:0px;
					}	
				}

				.spotlight-hero{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					position: absolute;
					z-index: 1;
					box-sizing: border-box;
					height: inherit;
					width: 425px;
					opacity: 0.95;
					padding: 40px 20px 20px;
					background-color: #53565A;
					color: white;
					font-size: 70px;

					@include mq("tablet", "max"){
						position:inherit;
					   height: 287px;
					   width: 100%;
					   opacity: 1;
					}

					@include mq("phone-wide", "max"){
						font-size: 60px;
					}

					&>div{
						display: flex;
						line-height: 0.8;
						&:first-of-type{
							font-size: 20px;
						}
					}

					button{
						// margin-top: 50px;
						color: white;
						background-color: $brand-color-btn-primary;
						min-height: 40px;
					}
				}
			}
	 	}

	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

			#featured-1{
				.product-list{
					width: 101%;
					margin: 0px;
					.product-card{
						min-height: 259px;
						max-width: none;
						flex-basis: 20%;
						min-width: 200px;
						margin: 0px;
						border-right: 1px solid;
    					border-bottom: 1px solid;
						border-radius: 0;

						&:first-of-type {
					        justify-content: space-around;
							&>h1{
								font-size: 28px !important;
							}
						}
						@include mq("phone-wide", "max"){
							min-width: 160px;
							&:nth-child(n+3){
								display: inherit;
							}
							&:nth-child(n+7){
								display: none;
							}

						}

						.product-image{
							flex:inherit;
						}
					}
				}
			}

			.about-us{
				background: none;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}
 	}
 }*/



 /*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.v1{
 	main{
 	// 	background-image: url("../images/supernexus.png");
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position-y: -123%;
		// background-color: #f2f2f2;
		// background-position: 174px 524px !important;
	 	
	 	.spotlight{
			.page-header{
				display: flex;
				flex-flow: nowrap;
	    		background-image: url("../images/spot-bg.jpg");
	    		background-size: cover;
	    		justify-content: center;

				@include mq("tablet", "max"){
					flex-direction:column-reverse;
					height: 100% !important;
				    min-height: unset !important;
				    max-height: unset !important;
				}

				@include mq("phone-wide", "max"){
					height: 290px !important;
				}

				&>div{
					&:first-of-type{
						height: inherit;
						@include mq("tablet", "max"){
							height: 287px;
						}
					}
				}

				.hero-carousel.slick-initialized.slick-slider{
					@include mq("tablet", "max"){
						height: 287px !important;
					}	
				}

				.spotlight-hero{
					display: flex;
					// flex-direction: column;
					justify-content: space-between;
					
					position: absolute;
					z-index: 1;
					box-sizing: border-box;
					
					height: inherit;
					width: 287px;
					opacity: 0.95;
					padding: 40px 20px 20px;
					// background-color: #53565A;
					color: #fff;
					font-size: 70px;
					width: 988px;
					background-image: url("../images/spot-img.png");
					background-repeat: no-repeat;
					height: 460px!important;
					background-position: right;
					margin-top: 26px;

					h1 {
						color: #fff;
					}

					#spot-text {
						display: flex;
						flex-flow: column;
						margin-top: auto;
						margin-bottom: auto;

						h1 {
							// border-bottom: 5px solid #ff000a;
							font-weight: bold;
							font-size: 50px;
							text-decoration: underline;
							text-decoration-color: #ff000a;
						}

						p {	
							color: #FFFFFF;		
							font-size: 24px;
							margin-top: 29px;
							margin-bottom: 31px;
						}
					}

					@include mq("tablet", "max"){
						position:inherit;
					   height: 287px;
					   width: 100%;
					   opacity: 1;
					}

					@include mq("phone-wide", "max"){
						font-size: 60px;
					}

					&>div{
						display: flex;
						line-height: 0.8;
						&:first-of-type{
							font-size: 20px;
						}
					}
				}
			}
	 	}

	 	.page-body{
	 		max-width: 958px;
	 		padding: 0;
	 		// margin: 0;
	 		position: relative;

	 		#home-banner {
	 			min-height: 250px;
	 			max-width: 958px;
	 			background-color: #FFFFFF;
	 			box-shadow: inset 0 0 125px rgba(23,36,48,0.25);
	 			display: flex;
	 			margin-bottom: 51px;
	 			padding: 12px 0px;
	 			border-radius: 5px;
	 			margin-top: 50px;
	 			// margin-left: 12px;
	 			// margin-right: 12px;

	 			img {
	 				margin-left: 33px;
	 			}
	 			
	 			// @media only screen and (min-device-width: 400px) and (max-device-width: 767px) {
	 			@include mq("tablet-small", "max") { 
	 				padding-bottom: 0;
	 				padding-top: 0;
	 				flex-flow: column;
	 				// margin-top: 12px;
	 				// margin-bottom: 12px;

	 				@include mq("phone-wide", "max") {
	 					margin-top: 0;
	 				}

	 				.banner {
	 					display: none;
	 				}
	 			}

	 			// .banner-full {
	 			// 	display: none;

	 			// 	@include mq ("tablet-small", "max") {
	 			// 		display: inline;
	 			// 	}
	 			// }

	 			// .banner {
	 			// 	@include mq("tablet-small", "max") {
	 			// 		display: none;
	 			// 	}
	 			// }

	 			// @include mq("tablet-small", "max") {
	 			// 	flex-flow: column;
	 			// 	margin-top: 12px;
	 			// 	margin-bottom: 12px;
	 			// }

	 			.banner-text {

	 				h1 {
	 				margin-top: 45px;	
	 				color: #737373;		
	 				font-size: 45px;
	 				margin-bottom: 31px;
	 				text-transform: none;

	 				}
	 				

	 				p {
	 					color: #737373;
	 					font-size: 24px;
	 					padding-bottom: 8px;
	 				}

	 				button {
 						height: 40px;
 						padding: 0 25px;
	 				}

	 			}

	 			@include mq("tablet-small", "max") {
	 				.banner-text {
	 					display: flex;
						flex-flow: column;
						background-image: url("../images/banner-img-mobile.png");
						height: 274px;
						background-repeat: no-repeat;
						// background-position-y:	36px;
						background-position-x:	center;
						background-size: contain;
						text-align: center;  

	 					h1 {
	 						margin: 0 auto;
	 					}

	 					p{
	 						font-size: 14px;
	 						margin: 0 auto;
	 					}

	 					button {
	 						width: 143.5px;
	 						margin: auto auto 15px;
	 					}
	 				}	
	 			
	 			}
	 		}

			#featured-1{
				.product-list{
					width: 101%;
					margin: 0px;
					.product-card{
						min-height: 259px;
						max-width: none;
						flex-basis: 20%;
						min-width: 200px;
						margin: 0px;
						/*border-right: 1px solid;*/
    					/*border-bottom: 1px solid;*/
						border-radius: 0;

						&:first-of-type {
					        justify-content: space-around;
							&>h1{
								font-size: 28px !important;
							}
						}
						@include mq("phone-wide", "max"){
							min-width: 160px;
							&:nth-child(n+3){
								display: inherit;
							}
							&:nth-child(n+7){
								display: none;
							}

						}

						.product-image{
							flex:inherit;
						}
					}
				}
			}			

			.about-us{
				// background: none;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}

 	}
 }