/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: fixed;
	width: 100%;
	background: #fff;
	z-index: 2;
	height: $header-height;
	

	@include mq('tablet', 'max') {
		height: 56px;
	}

	.site-header {
		height: $header-top-height;
		background: $header-top-background;
		font-weight: normal;
		font-family: $primary-font-face;
		font-size: 10px;
		color: #333;
		display: flex;
		align-items: center;

		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__welcome {
			padding: 7px 0;
			padding-left: 158px;
			font-size: 14px;
			text-transform: none;
			color: $header-top-font-color; 
			height: 100%;
			line-height: 40px; 
		}

		&__nav-menu {
			margin: 0;
			margin-right: 10px;
			height: 100%; 
			padding-left: 20px;

			li {
				display: inline-block;
				list-style-type: none;
				font-size: 14px;
				padding-left: 13px;
				padding-right: 13px;
				height: 100%; 
				line-height: 35px;
				cursor: pointer;
				font-weight: 500;

				&:hover{
					background: $header-top-background-hover;
				}


				a {
					color: $header-top-font-color;
					text-decoration: none;
					text-transform: capitalize;
					font-weight: normal;
					display: inline-block;
					height: 100%;
					line-height: 40px; 
				}
				
				a:hover {
					text-decoration: underline;

				}

			}

		}
 
		.site-header__left {
            max-height: $header-top-height;
			height: 100%; 
			display: flex;
            align-items: center; 
            margin-right: auto; 
            margin-left: 30px; 

			.currency-dropdown {
                height: 100%;
                display: flex; 
                height: 30px; 
                width: 115px;   
				select {
				    background: #FFF; 
			    } 

			}

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}

	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		font-weight: 700;
		padding-left: 0px;
		padding-right: 20px;
		height: $header-main-height;
		color: #333;
		font-family: $primary-font-face;
		background: $header-background;
		border-bottom: 1px solid #E4E2E2; 

		@include mq('tablet', 'max') {
			height: $header-mobile-height;
			padding-right: 49px;
		}

		@include mq('phone-wide', 'max') {
			height: $header-mobile-height;
			padding-right: 49px;
		}

		&__left, &__right {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.main-nav__right {
		flex-flow: row nowrap;
		margin-top: 13px;

		@include mq('tablet', 'max') {
			margin-bottom: 0;
			margin-top: 0;
		}

		.mdl-button--search {
			top: 0;
			bottom: 0;
			// right: 0;
			// margin-left: 216px;

			.material-icons {
				font-size: 27px;
				height: 27px;
				width: 27px;
			}

		}

		#searchField {
			min-width: 170px;
			border-bottom: none;
			padding: 0;
		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: center;
		}

		.search-textfield-container {
			position: relative;

			.mdl-textfield {

				&__label::after {
					background-color: $header-search-underline-color !important;
					left: 0;
					visibility: hidden;
					width: 100%;
				}

				&__expandable-holder {
					margin-left: 0px;
					border-bottom: 1px solid #0067B1;
					top: 0px;
					right: 12px;
					//max-width: 0px;
				}

				.mdl-button{
					position: relative;
				}
			}

			.mdl-textfield__input {
				color: $header-search-font-color;
				border-bottom-color: $header-search-font-color;
				padding-left: 0px !important;
				max-width: 0px;
				transition: max-width 0.2s;
			}
			
			.mdl-textfield__input:focus {
				max-width: 180px;
			}

			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: $header-icons-color;
			transform: translate(-14px, -11px);
		}

		.cart {
			margin-left: 8px;

			.cart-container {
				width: 34px;
				height: 34px;
				.material-icons::after {
					border: $header-minicart-badge-border;
					width: 12px;
					height: 12px;
					top: -2px; 
					right: -1px;
					font-size: 9px;
					background: $header-minicart-badge-color;
				}

			}

			.mdl-menu {
				&__container {
					right: -15px;
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: $brand-color;
						font-weight: 700;

						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
							}

							.cart-title {
								font-size: 23px;
								// text-transform: uppercase;
								line-height: 1.75;
								color: $primary-font-color; 

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;
								font-family: $secondary-font-face;

								.cart-item-img {
									display: flex;
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											font-weight: 700;
											font-size: 13px;
											line-height: 15px;
											text-decoration: none;
											color: $primary-font-color;
										}

									}

									.cart-item-quantity {
										flex: 1 50%;
										font-weight: 400;
										color: $primary-font-color;
									}

									.cart-item-price {
										flex: 1 50%;
										color: #5D8000; 
									} 

								}

							}

						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							} 

							.cart-subtotal {
								font-size: 18px;
								font-family: $secondary-font-face;
								font-weight: bold;
								color: $primary-font-color; 

								@include mq('tablet', 'max') {
									font-size: 15px;
								}

							}

							.checkout-btn:hover {
								background-color: $brand-color-btn-primary-hover;
							}

							.mdl-button--colored {
								font-family: $primary-font-face;
								font-size: 12px;
								font-weight: 700;
								letter-spacing: 1px;
								background: $brand-color;
								text-transform: uppercase;
								color: white;
								border-radius: 5px; 
							}

						}

					}

				}

			}

		}

	}

	.main-nav__left {
		// flex-flow: row wrap;
		padding: $header-logo-padding;
		padding-left: 25px;

		@include mq('tablet', 'max') {
			padding: 0;
		}

		@include mq('desktop', 'max'){
			flex-flow: row wrap;
		}

		.desktop-nav {
			@include mq('tablet', 'max') {
				display: none !important;
			}

			display: flex;
			flex-flow: nowrap;
			height: 100%; 
            align-items: center; 

			@include mq('desktop', 'max'){
				height: 125px;
			}

			ul:first-of-type {
				position: absolute;
			}

			& > ul {
				margin: 0px;
				display: flex;
				padding-inline-start: 35px;
				height: 40px;

				@include mq('desktop', 'max'){
					flex-wrap: nowrap;
					max-width: 508px;
				}
			}
			/*
				targets second list item for spacing between it and the first list item
			*/
			ul> li:nth-child(2) {
				margin-left: 14px;
			}

			& > ul > li {
				position: relative;
				display: inline-block;
				list-style: none;
			}

			& > ul > li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;

				.nav-handle {
					position: relative;
					margin-left: 0;

					a span {
						margin-right: 20px;
					}
				}

				> .nav-handle {
					
					&:after {
						font-family: 'Material Icons';
						content: '\e5cb';
						display: block;
						transform: rotate(-90deg);
						font-size: 26px;
						color: $brand-color; 
						position: absolute;
						right: 12px;
						top: 3px;
						clip: rect(6px, 22px, 32px, 0px);
					}

				}

				.sub-nav {
					z-index: 10;
					padding: 0;
					display: none;
					opacity: 0;
					padding: 5px 0px;

					&-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: nowrap;
						padding-top: 8px;
						padding-bottom: 8px;

						a {
							color: $brand-color-secondary;
							font-family: $secondary-font-face;
							text-align: left;
						}

						&:hover {
							background-color: $header-main-nav-background-hover;
						}

						a:hover {
							color: $brand-color; 
							text-decoration: none; 
						}
					}

					.nav-handle {
						padding: 0 24px;
					}

				}

				.sub-nav [data-subnav] {
					& > .nav-handle {
						padding-right: 45px;
						&::after {
							font-family: 'Material Icons';
							content: '\e5cc';
							display: block;
							font-size: 22px;
							color: $brand-color; 
							position: absolute;
							right: 10px;
							top: 4px;
							clip: rect(6px, 22px, 32px, 0px);
							text-decoration: none!important;

							&:hover {
								text-decoration: none!important;
							}
						}

						&:hover {
							background-color: $header-main-nav-background-hover;
						}

						a:hover {
							color: $brand-color; 
						}

					}
				}

			}

			& > ul > li {

				&:nth-child(4) {
					.nav-handle {
						min-width: 45px;
					}
				}

				.nav-handle {
					border-radius: 0;
					//min-width: 85px;
					padding: 0 10px;
					display: flex;
					flex-flow: column;
					justify-content: center;
					height: 40px;
 
					&:hover {
						background-color: $header-main-nav-background-hover; 
					}

				} 

				a {
					color: $brand-color-secondary;
					text-transform: none;
					text-decoration: none;
					font-family: $primary-font-face;
					&:hover { 
						color: $header-main-font-color-hover; 
					}
				}

				.nav-handle-line-1 {

					a {
						font-size: 15px;
						font-weight: bold;
						line-height: 1;
						margin-left: 10px;
						margin-right: 10px;
						
						&:hover {
							color: $brand-color;
						}
					}
				}

				.nav-handle-line-1-1 {
					//max-width: 105px;
					//padding-left: 0;
					height: 40px;
				}
			}

		}

		.logo {
			height: $header-logo-height; 
			width: $header-logo-width; 

			@include mq('tablet', 'max') {
				margin-bottom: 0;
			}

			@include mq('tablet', 'max') {
				max-height: calc(#{$header-mobile-height} - 20px); 
				width: auto;
				margin-left: $header-logo-padding;
			}
		}

	}
}

.mobile-drawer-nav {
	position: fixed;
	z-index: 100;
	pointer-events: none;
	font-family: $primary-font-face;

	.drawer-logo-container {
		background: $drawer-mobile-background;
		margin-top: 0;
		height: 56px;
		padding: 0px;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e4e2e2;

		.close-menu {
		    position: absolute;
		    right: 10px;
		    top: 16px;
		    color: $brand-color;
		    cursor: pointer;
		}

		img {
			padding: 25px;

			@include mq('tablet', 'max') {
				max-height: calc(#{$header-mobile-height} - 10px);
				width: auto;
				padding: 0;
				margin-left: 10px; 
				height:    36px; 
			}
		}
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		font-weight: bold;
		border: none;
		color: $secondary-font-color;
		text-transform: uppercase;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $header-icons-color;
			top: 1px;
			left: auto;
			right: -4px;
			pointer-events: all;
		}

	}

	.mdl-navigation {
		padding-top: 0;
		font-family: $primary-font-face;
		font-weight: 700;
		text-transform: capitalize;

		ul {
			font-size: 14px;
			padding-left: 20px;
		

			> * {
					line-height: 1.75em;  
			}
		}

	}

	ul {
		.mobile-nav-handle {
			a {
				color: $secondary-font-color;
				font-weight: 700;
			}

		}

		[data-subnav] {
			i.material-icons {
				color:    $primary-font-color; 
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;
		}

	}

	ul.top-buttons {
		li.top-btn {
			a {
				color: $primary-font-color; 
				font-weight: 700;
			}
			&:hover{
				a{
					color: $secondary-font-color;
				}
			}
		}

	}

	.back-button {
		cursor: pointer;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;
		color: $primary-font-color;
		font-size: 14px; 

		i.material-icons {
			color: $primary-font-color; 
			margin-right: 10px;
		}

	}

	.sub-nav-title {
		#text{
			color: $secondary-font-color;
		}
		font-weight: bold;

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 25px;
			color: $primary-font-color;  
			margin-right: 6px;
		}

	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;

		.mdl-textfield {
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
			padding-top: 14px !important;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid lightgray;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty, .mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $secondary-font-color; 
		}

	}

	// .search-textfield-container
}

//.mobile-drawer-nav

nav ul li a {
	text-decoration: none;
}

.page-header {
	padding-top: 165px; 
	background: #fff;
	display: block;

	@include mq("tablet", "max") {
		padding-top: 56px;
	}

	@include mq("tablet", "max") {
		flex-flow: column-reverse nowrap;
	}
}

.Quick {
    color: #000 !important;
    font-weight: 700 !important;
}