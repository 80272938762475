
/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.dropdown-container {
	margin: 6px;
	margin-top: 0px;

	select{
		width: 100% !important;
		font-size: 18px !important;
		color: $primary-font-color;
		font-family: $secondary-font-face;
		//border-top: 1px solid #FBFBFB !important;
	}
}
