/*
 * Flex UX Framework
 *
 * Filename: item-suggested-products.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.suggested-products {
	.suggested-products-header-container {
	    font-weight: 400;
		font-size: 23px;
	    font-family: $primary-font-face;
	    text-align: center;
	    padding: 30px 0px 15px 0px;
	    color: $secondary-font-color;
	    text-transform:  uppercase;
	

	    @include mq("tablet", "max") {
	    	text-align: center;
    		padding: 20px 0 14px;
	    }
	}

	.grid {
	    justify-content: center !important;
	    width: auto;
	}
}