/*
 * Flex UX Framework
 *
 * Filename: checkout-review-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-review .checkout-container .checkout-side-bottom p {
    width: 290px;
    font-size: 13px;
    text-align: center;
    margin: 15px auto 0;
    line-height: 1.5;
    //width: 645px;
    @include mq('tablet', 'max') {
        width: 650px;
    }
}

@media only screen and (max-width: 660px) {
    .checkout-review .checkout-container .checkout-side-bottom p {
        width: 95%;
    }
}
