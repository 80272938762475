/*
 * Flex UX Framework
 *
 * Filename: myaccount.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .my-account {
 	.page-header {
 		height: 0;
 	}
 }