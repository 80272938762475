/*
 * Flex UX Framework
 *
 * Filename: checkout-gift-certificate.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


