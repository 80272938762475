/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.footer {
	display: flex;
	flex-flow: row nowrap;	
	align-items: center;
	justify-content: space-between;
	
	background: $footer-background;
	color: #fff;
	font-family: $primary-font-face;
	font-size: 14px;
	height: 65px;
	min-height: 25px;
	padding: 0 25px;
	/*border-top: 2px solid $brand-color-secondary;*/

    @include mq("tablet", "max") {
        padding: 30px 20px 50px 20px;
    }	

	img {
		height: 44px;
		margin-right: 20px;
		display: none;

		@include mq("tablet", "max") {
		    height: 25px;
		    margin-bottom: 20px;
		    margin-right: 0px;
		}

	}

	a {
		color: #fff;
		text-decoration: none;
		// text-transform: capitalize;
		font-weight: initial;
		font-size: 14px;
		font-family: $secondary-font-face;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	#call-link {
		display: none;
	}

	> div {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-family: $secondary-font-face;
		font-weight: initial;
		line-height: 1.35em;

		@include mq("tablet", "max") {
		    flex-direction: column;
		    justify-content: space-around;
		}

		&:nth-of-type(2){
			@media only screen and (max-width: 330px) {
				margin-top: 15px;
				margin-bottom: 10px;	
			}
		}

	}

	@include mq("tablet", "max") {
		min-height: 150px;
		flex-flow: column nowrap;
		text-align: center;
		padding: 30px;

		#call-link {
			display: inline-flex;

			& > a {
				font-size: 20px;
				font-family: $primary-font-face !important;
			}
		}

		#call-link a {
			
			display: inline-flex;
			text-decoration: none;
		}

		#call-link a::before {
			content: '\e0cd';
			height: 25px;
			width: 25px;
			font-family: "Material Icons";
			font-size: 22px;
			// color: #fff;
			padding-right: 5px;
		}

		.footer-nav-menu {
			padding-top: 0;
		}
	}
}

