/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)

.about-us {
	max-width: 948px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	background: white;
	padding: 25px 0px;
	text-align: center;
	line-height: 1.5em;
	font-family: $primary-font-face;
	margin: 24px 12px;
	border-radius: 5px;

	@include mq("tablet", "max"){
		margin: 12px;
	    padding: 12px;
	}

	.title{
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		

		h1{
			font-size: $h1-font-size;
			margin-top: 0px;
		}
	}
	
	.copy{
		max-width: 640px;
		
		p{
			line-height: 1.5em;

			@include mq("tablet-small", "max"){
				padding-bottom: 5px;
			}

			a{
				white-space: nowrap;
			}
		}
	}
}
