/*
 * Flex UX Framework
 *
 * Filename: sort-by.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


#sort-by.btn {
    align-self: flex-end;
    padding: 3px 7px;
    padding-right: 10px;
    letter-spacing: 0;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: bold;
    order: 2;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    font-family: $secondary-font-face;
    border-radius: 5px;
    margin: 0 10px;
    color: $secondary-font-color;

    @include mq('tablet', 'max') {
        padding: 5px 10px;
        border-radius: 5px;
        order: 3;
    }

    @include mq('tablet', 'min') {
        padding: 5px 10px;
        min-width: 82px;
        // height: 41px;
        border-radius: 5px;
    }

    @include mq("phone-wide", "max") {
        padding: 4px 10px;
        color: $secondary-font-color;
        border-radius: 5px;
        margin: 0 5px;
    }

    &::after {
        font-family: 'Material Icons';
        content: '\e314';
        display: inline-block;
        transform: rotate(-90deg);
        font-size: 24px;
        color: $brand-color;
        position: relative;
    }

}

.sort-by-container {
    font-family: $secondary-font-face;
    font-size: 14px;

    .mdl-menu__container {
        left:initial !important;
        top:initial !important;
        margin-left: -36px;

        .mdl-menu__item:hover {
            background-color: $header-main-nav-background-hover;
        }

        a {
            text-decoration: none;
            color: $secondary-font-color;

            &:hover {
                text-decoration: none; 
                color: $header-main-font-color-hover; 
            }
        }

        @include mq('tablet', 'max') {
            margin-left: -61px;
        }
    }
}