/*
 * Flex UX Framework
 *
 * Filename: basket-empty.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-basket-empty {
	text-align: center;

	@include mq("tablet", "max"){
    	margin: 10px;
	}

//	.btn {
//   		font-family: $secondary-font-face;
//	}

    p {
    	margin-bottom: 16px;
    }
}
