/*
 * Flex UX Framework
 *
 * Filename: faqs.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	main {
		background: #fff !important;
	}

    .page-header {
        height: 0;
    }

	.page-body {

    	max-width: 610px;
        margin: 0px auto;
        padding: 12px 12px 50px 12px;
	}

	/*
	Fixing an odd bug where search icon in main-nav__right would not line up with cart icon
	*/

	.mdl-button--search {
		top: 7px !important;
	}

	.mdl-layout__drawer-button {
		@include mq('tablet', 'max') {
			top: 12px !important;
		}
	}

}