/*
 * Flex UX Framework
 *
 * Filename: faqs-renderer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	.page-body {

		.mdl-accordion {
			margin-top: 12px;
  			.mdl-accordion__icon {
    			margin-left: auto;
			}
		}
	}

	.mdl-accordion__button {
    	font-weight: 700;
    	font-size: 15px;
    	margin-left: 12px;
    	margin-top: 5px;
    	cursor: pointer;
        line-height: 1.15em;
        font-family: $secondary-font-face;
        color: #000;
        opacity: 1;
	}


	.mdl-collapse__content {
    	padding: 12px;
    	font-size: 14px;
    	line-height: 1.5em;
    	bottom: 11px;

    	font-family: $primary-font-face;

    	p {
            line-height: 1.5em;
    		font-size: 14px;
		}
	}	

	.mdl-accordion .mdl-accordion__icon{
		color: $brand-color;
	}

	.mdl-accordion.mdl-collapse--opened>.mdl-accordion__button>.mdl-accordion__icon{
		color: #000;
	}

	.mdl-accordion.mdl-collapse--opened{
		background-color: #F7F7F7;
		
		.mdl-accordion__button {
			padding-top: 10px;
		}
	}
}