/*
 * Flex UX Framework
 *
 * Filename: home-brand-banners.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home-banner-images-main-div {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px auto;
}

.home-banner-images{
  background-size: 80%;
  text-align: center;
  width:20%;
  height: 130px;
  min-width: 228px;
  margin: 6px;
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }

  @include mq("tablet-small", "max") {
    width: 45%;
    height: 150px;
    min-width: 120px;
  }

  @include mq("phone-wide", "max") {
    width: 45%;
    height: 115px;
    min-width: 120px;
  }

  div{
    width: 100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: flex-end;
    background-size: contain;

    span {
      font-size: 30px;
      color: #fff;
      text-shadow: 0px 0px 8px rgba(0, 0, 0, 1.0);
      padding-bottom: 10px;
      text-decoration: none;

      @include mq("tablet", "max") {
        font-size: 21px;
        padding: 0px 15px 10px 15px;
      }

      @include mq("phone-wide", "max") {
        font-size: 24px;
        padding: 0px 15px 10px 15px;
      }
    }
  }
}

.home-banner-images:nth-child(2){
  background-color: #009639;
}

.home-banner-images:nth-child(3){
  background-color: #ffffff;
}

.home-banner-images:nth-child(4){
  background-color: #58391F;
}

.home-banner-images:nth-child(5){
  background-color: #596345;
}

.home-banner-images:nth-child(6){
  background-color: #E6D5AB;
}

.home-banner-images:nth-child(7){
  background-color: #FFE49D;
}

.home-banner-images:nth-child(8){
  background-color: #4A4B4E;
}

.home-banner-images-title{
	position: relative;
	bottom: 15px;
}
