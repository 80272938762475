/*
 * Flex UX Framework
 *
 * Filename: category-header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.page-body {
	.products-header {
		.title {
			@include mq('tablet', 'max'){
				display:none;
			}
		}

		
	}
}
