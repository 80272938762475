/*
 * Flex UX Framework
 *
 * Filename: home.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home {
	.about-us {
    	//margin: 12px 12px 32px 12px;
    	//margin: 24px 0px ;
	}

	.page-body{
		padding: 1px 12px;

		@include mq('tablet', 'max') {
			padding: 0 10px;
		}
	}

	hr{
		height: 2px;
		width: 50px;
		outline: none;
		border: none;
		background-color: $brand-color-secondary;
		margin: 0 0 1em 0;
		display: none;
	}
	
	.welcome {

		display: flex;
		flex-flow: column;
		justify-content: center;
    	align-items: center;
    	text-align: center;
    	padding: 20px;
    	background-color: #fff;
    	padding-top: 40px;
    	padding-bottom: 40px;

    	@include mq("tablet", "max"){
			flex-direction: column;
		}

		h1 {
			padding-bottom: 10px;
			color: $brand-color;
			font-family: $primary-font-face;
			font-size: 30px;
			text-align: center;
			line-height: 1.15;

			// @include mq('phone-wide', 'max') {
			// 	padding-top: 15px;
			// }
		}

		p {
			color: $primary-font-color;
			font-family: $primary-font-face;
			font-size: 14px;
			line-height: 1.5em;
			max-width: 720px;
			margin: 0;
			padding-bottom: 8px;

			&:nth-of-type(2) {
				padding-bottom: 0;
			}
		}
	}

	.custom-orders {
		min-height: 233px;
		background-color: #8C8C8C;
	
		padding: 24px;
	

		&__container {

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			max-width: 948px;
			margin: 0 auto;
			background-color: #FFFFFF;
			height: 100%;
			text-align: center;
			padding: 15px;
			flex: 1 0 auto;

			h1 {
				color: $brand-color;
				font-family: $primary-font-face;
				font-size: 34px;
				text-align: center;
				text-transform: capitalize !important;
			}
 
			p {
				color: $primary-font-color;
				font-family: $primary-font-face;
				font-size: 14px;
				line-height: 1.5em;
				max-width: 567px;
				padding: 10px;

				@include mq("phone-wide", "max"){
					max-width: 100%;
				}
			}

		}
	}
}