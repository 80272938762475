/*
 * Flex UX Framework
 *
 * Filename: boldchat.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */   


