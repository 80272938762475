/*
 * Flex UX Framework
 *
 * Filename: category_filter.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


