/*
 * Flex UX Framework
 *
 * Filename: item.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.product-detail {
	
	main{
		// background-color: #f2f2f2;
		min-height: calc(100vh - 65px) !important;
		padding-top: 161px;

		@include mq('tablet', 'max'){
			padding-top: 56px;
		}
	}

	.page-header {
		margin: unset;
		height: 0;
	}

	&-page-container {
    	width: 100%;
    	padding: 0;
    	max-width: 1175px;
    	margin: 25px auto 12px;
    	font-family: $primary-font-face;

    	@include mq("tablet", "max"){
    		flex-direction: column;
		    width: calc(100% - 20px);
		    margin-top: 10px;
		    margin: 10px auto 20px;
		}

		// @include mq("phone-wide", "max") {
		// 	margin-top: 0;
		// }
	}

	&-panel {
    	// border-radius: 5px;
    	padding: 0;
    	background: none;
    	display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		font-family: $primary-font-face;

		@include mq("tablet", "max"){
			flex-direction:column;
		}
	}

	.product-main-container {
		@include mq("tablet", "min") {
			margin-right: 25px;
    		width: 600px;
    	}
	}

	.product-details-container {
    	width: calc(100% - 625px);
    	background: white;
    	padding: 20px;
    	border-radius: 5px;
    	box-sizing: border-box;
    	//height: min-content;
    	height: max-content;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		   height: 100%;
		}

    	@include mq("tablet", "max"){
    		width: 100%;
    		margin-top: 10px;
    	}

    	.select-option-btn:after {
		    font-size: 24px;
		}
	}
}

.artwork-control {
	//padding: 20px 10px;
	padding: 20px;
	border: 1px solid #e2e2e280;
	margin-top: 10px;
	
	p:first-of-type{
		margin: 10px 0;
	}
	.uploadifive-button {
		margin: 15px 0;
		min-height: 45px;
		background-color: transparent;
		color: #333;
		border: 1px solid;
		font-size: 16px;
		font-weight: 800;
		padding: 0 30px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 130px !important;
		z-index: 60;
		&:hover {
			color: #FFF;
			background-color: #0067B1;
			border-color:  #0067B1;
		}
	}
	.artwork-control--previous {
		display: flex;
		align-items: center;
		#previous_artwork {
			-webkit-appearance: checkbox;
		}
		label {
			margin-left: 10px;
		}
	}
}