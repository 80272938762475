/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home-contact-us {
    width: 100%;
    background: #005D9C;
    display: flex;
    justify-content: center;

    &__content {
		max-width: 948px;
		text-align: center;
		box-sizing: border-box;
		background: #fff;
		border-radius: 5px;
		flex: 1 1 100%;
		padding: 20px;
		margin: calc(12px + 12px);
    	margin-right: calc(24px);
    	margin-left: calc(24px);
		margin-left: calc(12px + 12px + 12px);
		margin-right: calc(12px + 12px + 12px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include mq("phone-wide", "max") {
			margin: 22px 12px;
		}

		.contact-us-grid {
			padding: 0;
			display: flex;
			justify-content: center;
			/*margin-top: 20px;*/

			.mdl-cell {
				margin: 0;

				&:first-of-type {
					@media only screen and (max-width: 840px) {
						margin-bottom: 10px;
					}
				}
			}

    	}

		.title{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-family: $primary-font-face;
			line-height: 0;
			text-align: center;

			hr{
				height: 2px;
				margin: 0px;
			}
		}

		h1 {
			color: $h1-font-color;
			padding-bottom: 0;
			font-size: 30px;
			margin: 0px;
			font-weight: 700;
			margin-bottom: 15px;
			line-height: 1em;
		}

    	p {
    		font-size: 14px;
			line-height: 1.5em;
			margin-top: 12px;
			padding: 0px;
			margin: 0px auto;
			max-width: 375px;
			font-family: $primary-font-face;
			padding: 0px 30px;

			@include mq("tablet-small", "max") {
				padding: 0px 15px;
			}
    	}

    	a {
    		font-family: $primary-font-face;
    		text-decoration: underline;
			color: $brand-color;
			line-height: 1.5em;
			font-weight: 400;
			font-size: 14px;

			&:hover{
				color: $link-hover;
			}
    	}
    }
}